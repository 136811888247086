import axios from 'axios';

export function Interceptor (){
  // Interceptor for adding any access token to the APIS
    axios.interceptors.request.use(
        (config) => {
          config.headers.Authorization = `Bearer ${localStorage.getItem("access_token")}`
          // config.headers["x-csrf-token"] = `${localStorage.getItem("csrf_token")}`
          return config;
        },
        (error) => {
          // Handle any request errors
          return Promise.reject(error);
        }
      );
}