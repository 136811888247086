import React, { useEffect, useState } from 'react'
import { Spin, message } from 'antd';
import Editor from '../components/Editor';
import { getAppData, postCall } from '../components/CommonApiCall';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch, Space } from 'antd';

function HowItWorks() {
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState('')
  const [active, setActive] = useState('')

  useEffect(() => {
    getData()
  }, [])

  // to fetch the data
  const getData = async () => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)
      const htmlData = htmlDecode(response?.data?.data?.aboutUs)
      setText(htmlData)
      setId(response?.data?.data?.id)
      console.log(response?.data?.data?.id)
      if(response?.data?.data?.about_us_status == 1){
        setActive(true)
      }else setActive(false)
    }
    const failureCb = (response) => {
      setLoading(false)
    }
    getAppData("aboutus", successCb, failureCb);
  }


  function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  }

  //**** Update The Data ***//
  const handleChange = (value) => {
    setLoading(true)
    setText(value);
    let data = {
      _id: id,
      type: "about",
      content: value,
    }
    let url = '/admin/update_app_data'
    const successCb = (res) => {
      setLoading(false)
      getData()
      message.success("Data Updated Successfully")
    }
    const failureCb = (res) => {
      setLoading(false)
    }
    postCall(data, url, successCb, failureCb)
  };

  const handleToggleChange = (e)=>{
    console.log("data content", e == true ? 1 : 0)
    console.log("eeeee1111",e)
    setLoading(true)
    let data = {
      _id: id,
      type: "about_us_status",
      content:  e === true ? "1" : "0",
    }
    console.log("data content",data)
    // return false

    let url = '/admin/update_app_data'
    const successCb = (res) => {
      setLoading(false)
      getData()
      message.success("Data Updated Successfully")
    }
    const failureCb = (res) => {
      setLoading(false)
    }
    postCall(data, url, successCb, failureCb)
  }
console.log("active",active)
  return (
    <div className='Maincard p-3'>
      <div className='d-flex justify-content-between align-items-center'>
        <h3>How It Works</h3>
        <Space direction="vertical">
          {(active !== '') && <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked={active}
            size='default'
            onChange={handleToggleChange}
          />}
        </Space>
      </div>
      {!loading && <Editor text={text} handleUpdate={handleChange} />}
      {loading && <Spin size="large" className='loader' />}
    </div>
  )
}


export default HowItWorks