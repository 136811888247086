import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Button, Form, Input, Spin, message } from "antd";
import logo from '../assets/images/Logo_Horizontal.png'
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons"
import { apiCall } from "../components/ApiCall";
import OtpInput from 'react-otp-input';
import bdlok8r from '../assets/images/bg_lok8r.png'
import { Icon } from '@iconify/react';
import { baseUrl } from "../constants/const";
import { postCall, postCallResetPassword } from "../components/CommonApiCall";



const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formReset] = Form.useForm();
  const [screen, setScreen] = useState("login");
  const [id, setID] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const containerStyle = {
    background: `url(${bdlok8r})`, // Replace with your image URL
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%', // Set the width and height as needed
    height: '300px',
  };

  const onLoginFinish = async (values) => {
    const email = values?.email;
    const password = values?.password;
    if (!email && !password) {
      setError("Email and Password Is Required")
    } else {
      let data = {
        email,
        password
      }
      let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/admin/login`,
        data,
      };
      try {
        let response = await apiCall(config);
        console.log("response login", response)
        if (response?.status == 200) {
          let token = response?.data?.tokens?.access?.token;
          let refreshToken = response?.data?.tokens?.refresh?.token;
          let cs_token = response?.data?.tokens["x-csrf-token"];
          console.log("x", cs_token)
          let user_data = response?.data?.data
          message.success(response.data.message)
          window.localStorage.setItem('access_token', token)
          window.localStorage.setItem('csrf_token', cs_token)
          window.localStorage.setItem('refresh_token', refreshToken)
          window.localStorage.setItem('user_data', JSON.stringify(user_data))
          return navigate("/overview");
        } else {
          console.log("response?.message", response)
          message.error(response.data.message)
        }
      } catch (err) {
        message.error("Network Error")
      }
    }
  };

  const onOtpVerify = (values) => {
    setError("");
    let data = {
      email,
      otp: values?.Otp,
    }
    setLoading(true)
    let url = '/admin/verifyOtp'
    const successCb = (res) => {
      console.log("token1111111111", res?.data?.token)
      window.localStorage.setItem('access_token', res?.data?.token?.data)
      setLoading(false)
      setScreen("setpass")
      setID(res?.data?.data?._id)
      message.success(res?.data?.message)
    }
    const failureCb = (res) => {
      console.log("failure res", res)
      setLoading(false)
      message.error(res?.data?.message);
    }
    postCall(data, url, successCb, failureCb)
  }

  const onFinishForgot = (values) => {
    console.log("=====", email, values?.email)
    if (values?.email) setEmail(values?.email);
    let data = {
      email: values?.email ? values?.email : email,
    }
    setLoading(true)
    let url = '/admin/forgotPassword'
    const successCb = (res) => {
      console.log("succcess", res)
      setLoading(false)
      message.success(res?.data?.message);
      // setEmail(values?.email)
      setScreen("sendOtp")
    }
    const failureCb = (res) => {
      console.log("response failure", res)
      setLoading(false)
      message.error(res?.data?.message);
    }
    postCall(data, url, successCb, failureCb, "emailVerify")
  }
  const handlePasswordReset = async (formValues) => {
    form.resetFields();
    const successCb = (res) => {
      setLoading(false)
      formReset.resetFields();
      message.success(res?.data?.message)
      setScreen("login")
    }
    const failureCb = (res) => {
      setLoading(false)
      message.error(res?.data?.message)
    }
    if (formValues?.password) {
      let data = {
        _id: id,
        password: formValues?.password,
      }
      let url = '/admin/resetPassword'
      postCallResetPassword(data, url, successCb, failureCb, "emailVerify")
    }
  }

  const validatePassword = (_, value) => {
    const { password } = formReset.getFieldsValue();
    if (value !== password) {
      return Promise.reject('Passwords do not match');
    }
    return Promise.resolve();
  };

  return (
    <div className="h-100 my-0 d-flex" style={containerStyle}>
      <div className=" box w-lg-25 w-sm-50 mx-auto my-auto p-md-5 p-sm-3" style={{ backgroundColor: "white", borderRadius: "10px", width: "42%" }}>
        <div className="mx-auto" style={{ width: "40%" }}>
          <img className="mx-auto pt-2 pb-5" src={logo} style={{ width: "100%" }} />
        </div>
        <div className="formClass mb-3 px-3">
          <h5 className="fw-bold text-center">Welcome</h5>
          <div className="text-center">Please enter your information to log into the system.</div>
        </div>
        {loading && <Spin size="large" className='loader' />}
        {/* {error && <Alert variant="danger">{error}</Alert>} */}
        {screen == "login" &&
          <Form
            className="formClass"
            name="login-form"
            form={form}
            onFinish={onLoginFinish}
            layout="vertical"
          >

            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: 'Please enter your Email Id!' }]}
            >
              <Input placeholder="Email*"
                // prefix={<Icon icon="ant-design:mail-filled" color="#454cc9" />}
                iconRender={<Icon icon="ant-design:mail-filled" color="#454cc9" />} />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please enter your password!' }]}
            >
              <Input.Password
                placeholder="Password*"
                iconRender={(visible) =>
                  visible ? (
                    // <Icon icon="ant-design:mail-outlined" color="#454cc9" />
                    <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                  ) : (
                    <EyeOutlined onClick={togglePasswordVisibility} />
                  )
                }
              />
            </Form.Item>
            <Form.Item>
              <div className="forgot_pass" onClick={() => setScreen("email")}>Forgot password?</div>
            </Form.Item>
            <Form.Item className="mx-auto text-center">
              <Button type="primary" htmlType="submit" className="button_common text-center">
                Log In
              </Button>
            </Form.Item>
            {/* <div className="dont_acc" onClick={() => { setError(""); }}>Don't have an account? <span style={{ color: "var(--purpleBg)", fontWeight: "600" }}>Sign Up</span></div> */}
          </Form>
        }
        {screen == "email" &&
          <div className="d-flex flex-column justify-content align-items-center">
            <h5 className=' mb-0 mt-3'>Forgot Password?</h5>
            <p className='mt-2 sub_text mb-0' style={{ fontWeight: 500 }}>Enter the email associated with your account.</p>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form name="Auth" className="formClass mt-2" initialValues={{ remember: true, }} onFinish={onFinishForgot}  >
              <Form.Item
                name="email"
                // label={<span style={{ fontWeight: 500 }}>Email</span>}
                autoComplete="off"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: 'Please Enter your email!',
                  },
                ]}>
                <Input type='email' className='input_box' placeholder="Email Address" />
              </Form.Item>
              <Form.Item className='text-center'>
                <Button type="primary" htmlType="submit" className="button_common text-center w-50" style={{ marginTop: 100 }} >
                  Next
                </Button>
              </Form.Item>
            </Form>
          </div>
        }
        {
          screen == "sendOtp" &&
          <div className="d-flex flex-column justify-content align-items-center">
            <h5 className=' mb-0 mt-3'>Forgot Password?</h5>
            <p className='mt-2 sub_text mb-2' style={{ fontWeight: 500 }}>Enter OTP sent to Email</p>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form name="otp" initialValues={{ remember: true, }} onFinish={onOtpVerify}  >
              <Form.Item name="Otp"
                autoComplete="off"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: 'Enter the 4 digit OTP',
                  },
                ]}>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  inputType="tel"
                  renderSeparator={<span>-</span>}
                  inputStyle={{ width: "3rem", height: "3rem" }}
                  renderInput={(props) => <input {...props} />}
                />
              </Form.Item>
              <Form.Item className='text-center'>
                <Button type="primary" htmlType="button"
                  className="button_common text-center w-48 me-2"
                  onClick={onFinishForgot}
                  style={{ marginTop: 100 }} >
                  Resend
                </Button>
                <Button type="primary" htmlType="submit" className="button_common text-center w-48" style={{ marginTop: 100 }} >
                  Verify
                </Button>
              </Form.Item>
            </Form>
          </div>
        }
        {
          screen == "setpass" &&
          <div className="d-flex flex-column justify-content align-items-center">
            <h5 className=' mb-0 mt-3'>Forgot Password?</h5>
            <p className='mt-2 sub_text mb-2' style={{ fontWeight: 500 }}>Enter new Password</p>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form
              className="reset_form"
              form={formReset}
              name="resetPassword"
              onFinish={handlePasswordReset}
            // labelCol={{ span: 7 }}
            // wrapperCol={{ span: 16 }}
            >
              <Form.Item
                name="password"
                // label="Password"

                className="mt-2"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password placeholder="New Password*" />
              </Form.Item>

              <Form.Item
                name="confirmPassword"
                // label="Confirm Password"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  {
                    validator: validatePassword,
                  },
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
              {/* <Form.Item > */}
              <Button type="primary" htmlType="submit" className="button_common text-center w-100 mb-3" style={{ marginTop: 20 }} >
                Change Password
              </Button>
              {/* </Form.Item> */}
            </Form>
          </div>
        }
      </div>
    </div>
  );
};

export default Login;