import { Button, Form, Table, message, Row, Spin } from 'antd';
import React, { useState, useEffect } from 'react'
import ModalQuestion from '../components/ModalQuestion';
import { getDataCallContact, getDataCallResult, postCall } from '../components/CommonApiCall';
import Search from 'antd/es/input/Search';


function ContactUs() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [form] = Form.useForm();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false)
  const [replyId, setReplyId] = useState(false)

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    // pageSizeOptions: ['5', '10', '25', '50', '100'],
    // showSizeChanger: true,
    position: ['bottomRight'],
    // size: 'small'
  });
  const [openRplyModal,setOpenRplyModal] = useState();

  useEffect(() => {
    getData();
  }, [pagination?.current, pagination?.pageSize, searchText])

  //** Called to fetch the users data record **//
  const getData = async () => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response?.data?.other?.total_entries,
      }));
      console.log("test me first reached or nt" , response)
      let datas = response?.data?.data?.map((item) => {
        return ({
          _id: item?._id,
          key: item?._id,
          name: item?.name,
          email: item?.email,
          message: item?.message,
        })
      })
      console.log("999999999999999",datas)
      setData(datas)
    }
    const failureCb = (response) => {
      setLoading(false)
      setData('')
      setPagination({
        current: 1,
        total: 0,
        pageSize: 5,
      });
    }
    // (page, limit, keyword, get_url=null, successCb, failureCb, type = '')
    getDataCallResult(pagination?.current,pagination?.pageSize,searchText,"/admin/contact_us_list", successCb, failureCb);
  }


  const dataSource = [
    {
      key: '1',
      Name: 'Mike',
      Email: "mike@gmail.com",
      Message: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution",
    },
    {
      key: '2',
      Name: 'Mike',
      Email: "mike@gmail.com",
      Message: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution",
    },
    {
      key: '3',
      Name: 'Mike',
      Email: "mike@gmail.com",
      Message: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution",
    },
    {
      key: '4',
      Name: 'Mike',
      Email: "mike@gmail.com",
      Message: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution",
    },
    {
      key: '5',
      Name: 'Mike',
      Email: "mike@gmail.com",
      Message: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution",
    },
    {
      key: '6',
      Name: 'Mike',
      Email: "mike@gmail.com",
      Message: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution",
    },
  ]

  // handler for selected rows // 
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleReplyQuestion = (values) => {
    console.log("reply question", values)
    let data = {
        _id: replyId,
      message: values?.Message

    }
console.log(data,"+++++++++++++++++")
let url = '/admin/contact_reply'
    const successCb = (res) => {
      setLoading(false)
      console.log(res)
      form.resetFields()
      getData()
      setOpenRplyModal(false)
      message.success("Data Updated Successfully")
      setReplyId("")
    }
    const failureCb = (res) => {
      setLoading(false)
    }
    postCall(data, url, successCb, failureCb)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

   // Triggered on Change Page Number
   const handlePageChange = (page, pageSize) => {
    setPagination((prevPagination) => ({
        ...prevPagination,
        current: page,
        pageSize: pageSize,
    }));
};

  const onClickReply = (record) =>{
    setOpenRplyModal(true)
    setReplyId(record?._id)
  }

  // columns for the table //
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      render: (_, record) => {
        console.log("record reply", record)
        return (
          <>
          {/* {console.log("record", record)} */}
            <Button className='purpleButton px-4' onClick={()=> onClickReply(record)}>Reply</Button>
          </>)
      },
    },
  ];

   //*** to handle the Search Value ***//
   const handleSearch = (value) => {
    setSearchText(value);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: 1,
    }));
  };

  return (
    <div>
      {loading && <Spin size="large" className='loader' />}
      <Row className='justify-content-between align-items-center my-3'>
        <h4 className='fw-bold'>Contact Us</h4>
          <Search
            placeholder="Search by name.."
            onSearch={handleSearch}
            allowClear
            style={{ width: "30%", float: "right", marginBottom: "10px" }}
          />
        </Row>
     { !loading && <Table
        // rowSelection={rowSelection}
        dataSource={data}
        columns={columns}
        scroll={{ x: "250px" }}
        pagination={{
          ...pagination,
          onChange: handlePageChange,
        }} />}
        {openRplyModal && <ModalQuestion
        form={form}
        open={openRplyModal}
        title="Reply"
        type="Reply"
        onCancel={() => {setOpenRplyModal(false); setReplyId(null)}}
        onFinish={handleReplyQuestion}
      />}
    </div>
  )
}

export default ContactUs