import React from "react";
import { useNavigate, useParams} from "react-router-dom";
import Login from '../screens/Login';


const ProtectedRoute = ({ children }) => {
    const navigate  = useNavigate()
    let { userId } = useParams();
    console.log("user",userId)
    const token  = window.localStorage.getItem("access_token");
    if (!token) {
       navigate("/");
       return <Login />
    //    return null;
    }
    else {
        return children;
    }
};

export default ProtectedRoute;