import React from 'react'
import { Layout } from 'antd';
import Routes from '../routes/CommonRoutes'
import Header from '../components/Header';
import { Interceptor } from '../components/Interceptor';
import MenuApp from '../components/MenuApp';

const { Content } = Layout;

function Home() {
    Interceptor();
    console.log("home component")
    return (
        <div>
            <Header / >
            <Layout style={{ minHeight: '100vh' }}>
                 <MenuApp /> 
                <Layout>
                    <Content style={{ padding: '24px' }}>
                        <Routes />
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default Home