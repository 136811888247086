import './App.css';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './routes/ProtectedRoute';
// import Login from './screens/Login';
import Home from './screens/Home';
import React, { lazy, Suspense } from 'react';
import bgLoading from './assets/images/bgLoading.png'
import lok8r_center from './assets/images/lok8r_center.svg'
const Login = lazy(() => import('./screens/Login'));

function App() {
  return (
    <div className='h-100'>
      <Suspense fallback={
                          <div className='initialLoading' style={{ backgroundImage: `url(${bgLoading})` }}> 
                          <img src={lok8r_center} alt="" />
                            </div>
                         }>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
      </Routes>
      </Suspense>
    </div>

  );
}

export default App;
