import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../constants/const";

// method to call APIS and return the promise
export const apiCall = (config) => {
    // const navigate = useNavigate();
    return new Promise((resolve, reject) => {
        axios({
            method: config?.method,
            url: config?.url,
            headers: {
                ...config?.headers,
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            },
            data: config?.data || {},
            timeout: 60000,
            Authentication: config?.Authentication
        }).then(async (response) => {
            console.log("api call response then", response)
            let responseData = {
                status: response?.status ?? 100,
                data: response?.data
            }
            resolve(responseData)
        }).catch(async (error) => {
            let response = error?.response;
            if (response?.status === 401) {
                resolve(response)
                localStorage.removeItem('token');
                // navigate('/');
            }
            console.log("api call response catch", response)
            // if (response?.status === 410 && response?.data?.type == "jwt_expired") {
            if (response?.status === 410) {
                const refresh_token = localStorage.getItem("refresh_token");
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');

                if (refresh_token) refreshToken(refresh_token);
            }
            let responseData = {
                status: response?.status ?? 100,
                data: response?.data
            }
            resolve(responseData)

        });
    });
};


const refreshToken = (refresh_token) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: `${baseUrl}/admin/refresh-tokens`,
            data: {
                refreshToken: refresh_token,
            }
        })
            .then(async (response) => {
                resolve(response);
                console.log("response refresh", response);
                let token = response?.data?.access?.token;
                let refreshToken = response?.data?.refresh?.token;
                // dispatch(AuthData(response?.data?.token))
                window.localStorage.setItem('access_token', token)
                window.localStorage.setItem('refresh_token', refreshToken)
                window.location.reload();
            })
            .catch(async (error) => {
                console.log("error == refreshToken", error)
            });
    });

};