import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../screens/Home';
import Overview from '../screens/Overview';
import CustomNotification from '../screens/CustomNotification';
import Faq from '../screens/Faq';
import UserReporting from '../screens/UserReporting';
import TermsOfService from '../screens/TermsOfService';
import HowItWorks from '../screens/HowItWorks';
import PrivacyPolicy from '../screens/PrivacyPolicy';
import ContactUs from '../screens/ContactUs';
import Introductory from '../screens/Introductory';

//  All routes accessible with token 
function CommonRoutes() {
  return (
    <>
      <Routes>
        {/* <Route path="/" element={ <Home/> } /> */}
        <Route path="/overview" element={ <Overview/> } />
        <Route path="/custom_notification" element={ <CustomNotification/> } />
        <Route path="/faq" element={ <Faq/> } />
        <Route path="/user_list" element={ <UserReporting/> } />
        <Route path="/termsofservice" element={ <TermsOfService/> } />
        <Route path="/howitworks" element={ <HowItWorks/> } />
        <Route path="/privacypolicy" element={ <PrivacyPolicy/> } />
        <Route path="/contactus" element={ <ContactUs/> } />
        <Route path="/introductory" element={ <Introductory/> } />
     </Routes>
    </>
  )
}

export default CommonRoutes
