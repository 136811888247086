import React, { useState } from 'react'
import logoSquare from '../assets/images/Logo Sqaure.png';
import logoIcon from '../assets/images/Logo_Horizontal.png';
import { Layout, Menu, Dropdown, Avatar, Select } from 'antd';
import { UserOutlined, LogoutOutlined, DownOutlined, UpOutlined  } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
const { Header: HeaderBar } = Layout;

function Header() {
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleVisibleChange = (visible) => {
        setIsDropdownOpen(visible);
      };

    const handleLogout = () => {
        console.log("Reached inside the handle logout function")
        sessionStorage.clear();
        localStorage.clear();
        localStorage.removeItem('token');
        navigate('/');
    };

    //  Menu for the admin profile , logout
    const userMenu = (
        <Menu style={{borderColor:"red"}}>
            <Menu.Item key="logout" icon={<LogoutOutlined  style={{color:"#8B64DF"}}/>} onClick={() => handleLogout()} style={{backgroundColor:"#EAE1FF", borderColor:"#EAE1FF"}}>
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <HeaderBar className=" d-flex justify-content-between align-items-center border">
            <img src={logoIcon} style={{ width: 150 }} />
            <div className="user-profile" style={{ minWidth: 120 }}>
                <Dropdown 
                    overlay={userMenu}
                    open={isDropdownOpen}
                    onOpenChange={handleVisibleChange}
                >
                    <div className='admin' >
                        {/* <span className=''>
                            <Avatar icon={<UserOutlined />}
                                size="small"
                                style={{
                                    backgroundColor: '#fde3cf',
                                    color: '#f56a00',
                                }} /> */}
                            <span className='fw-bold mx-lg-3'>Admin</span>
                        {/* </span> */}
                        {isDropdownOpen ? <UpOutlined /> : <DownOutlined />}
                        {/* <DownOutlined size="small" /> */}
                    </div>
                </Dropdown>
            </div>
        </HeaderBar >
    )
}

export default Header