import { Button, Form, Input, Modal, message } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React from 'react'

function ModalQuestion({ form, open, title, data = 'null', onCancel, onFinish, type }) {
  const [formm] = Form.useForm();
  const onModalFinish = (values) => {
    console.log("modal values", values)
    if(values.Name){
    if (!(values.Name).trim() || !(values?.Message).trim()) {
      message.error("Both the Fields are Required")
      return false
    }}
    return onFinish(values)
  }
  // console.log("data", form);
  form.setFieldsValue({
    Name: data?.title,
    Message: data?.message,
  });
  return (
    <>
      <Modal
        title={title}
        centered
        open={open}
        key={open}
        onCancel={() => onCancel()}
        footer={null}
        width={420}
      >
        <Form form={form} onFinish={onModalFinish} >
          {type == "Reply" ? <Form.Item name="Message"
            initialValue={data?.message}
            rules={[{ required: true, message: 'Please Enter Your Reply Message!' }]} label="Reply Message" labelCol={{ span: 24 }}>
            <TextArea placeholder='Message..' rows={5} />
          </Form.Item>
            :
            <>
              <Form.Item name="Name"
                initialValue={data?.title}
                rules={[{ required: true, message: 'Please Enter The Question Name' }]} label="Question &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" labelCol={{ span: 24 }}>
                <Input placeholder='Question' />
              </Form.Item>
              <Form.Item name="Message"
                initialValue={data?.message}
                rules={[{ required: true, message: 'Please Enter Your Answer!' }]} label="Answer" labelCol={{ span: 24 }}>
                <TextArea placeholder='Answer' rows={5} />
              </Form.Item>
            </>
          }
          <Form.Item className='mx-auto'>
            <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} htmlType='submit'>{type}</Button>
            <Button danger type="primary" className='w-48 ' onClick={() => onCancel()}>Cancel</Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}


// function ModalQuestion({ form, open, title, data = null,onCancel,onFinish, type}) {
//     const onModalFinish = (values)=>{
//             console.log("modal values", values)
//              return onFinish(values)
//     }
//     let label_name, label_desc ;
//     if(type == "Reply") { label_name = "Question" ; label_desc = "Answer"}
//     else { label_name = "Name" ; label_desc = "Description"}
//     console.log("data", data?.title, data?.message)
//   return (
//     <>
//     <Modal
//         title={title}
//         centered
//         open={open}
//         onCancel={()=>onCancel()}
//         footer={null}
//         width={400}
//       >

//         <Form form={form} onFinish={onModalFinish} >
//           <Form.Item name="Name"
//                     // initialValue = {data?.title} 
//                     rules={[{ required: true, message: 'Please Enter The Question Name' }]} label={label_name} labelCol={{span:24}}>
//             <Input placeholder={label_name} />
//           </Form.Item>
//           <Form.Item name="Message"
//                     // initialValue = {data?.message} 
//                     rules={[{ required: true, message: 'Please Enter Your Message!' }]} label={label_desc} labelCol={{span:24}}>
//             <TextArea placeholder={label_desc} rows={5} />
//           </Form.Item>
//           <Form.Item className='mx-auto'>
//             <Button  type="primary" className='me-2 w-48' style={{backgroundColor:"#6240C9"}} htmlType='submit'>{type}</Button>
//             <Button danger type="primary" className='w-48 ' onClick={()=>onCancel()}>Cancel</Button>
//           </Form.Item>
//         </Form>
//       </Modal>
//     </>
//   )
// }

export default ModalQuestion