import React, { useState } from 'react';
import { Layout, Menu, Button, Drawer } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import logoNoti from '../assets/images/m_notification_light.png';
import logoNotiDark from '../assets/images/m-notification-dark.png';
import logoOverview from '../assets/images/m_dashboard_light.png';
import logoOverviewDark from '../assets/images/m-dashboard-1.png';
import logoFaq from '../assets/images/m_faq.png';
import logoFaqDark from '../assets/images/faq-1.png';
import logoUser from '../assets/images/users.png';
import logoUserDark from '../assets/images/users-1.png';
import logoContact from '../assets/images/m-contact.png';
import logoContactDark from '../assets/images/m-contact-1.png';
import logoHow from '../assets/images/m-how.png';
import logoHowDark from '../assets/images/m-how-1.png';
import logoIntro from '../assets/images/m-introfile-richtext-fill.png';
import logoIntroDark from '../assets/images/m-introfile-richtext-fill-1.png';
import logoPrivacy from '../assets/images/m-privacyPath 38898.png';
import logoPrivacyDark from '../assets/images/m-privacyPath 38899.png';
import logoTerm from '../assets/images/m-termfile-text.png';
import logoTermDark from '../assets/images/m-termfile-text-1.png';
import {
  ContainerOutlined,
  MailOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';


function MenuApp() {
  const { Sider } = Layout;
  const location = useLocation();
  const [collapsedDrawer, setCollapsedDrawer] = useState(false)
  const [collapsed, setCollapsed] = useState(false)
  const [selectedKeys, setSelectedKeys] = useState([location?.pathname]);

  const handleMenuClick = (e) => {
    setSelectedKeys([e.key]);
  };

  const handleBreakpoint = (broken) => {
    if (broken) {
      setCollapsed(true); // if the screen is broken (md and below), collapse the sider
    } else {
      setCollapsed(false); // if the screen is not broken (lg and above), expand the sider
    }
  };
  return (
    <>
      {collapsed &&
        <Button type="text" onClick={() => setCollapsedDrawer(!collapsedDrawer)} className="ps-3" icon={collapsedDrawer ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} />
      }
      <Sider width={200} theme="light"
        trigger
        breakpoint="md"
        collapsedWidth="0"
        collapsible
        onBreakpoint={handleBreakpoint}>
        <Menu mode="inline" theme="light"
          selectedKeys={selectedKeys}
          onClick={handleMenuClick}
          style={{ paddingTop: "10px" }}>
          <Menu.Item key="/overview" icon={<img className='w-10' src={selectedKeys[0] == '/overview' ? logoOverviewDark : logoOverview} alt="icon" />}>
            <Link to="/overview">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="/user_list" icon={<img className='w-10' src={selectedKeys[0] == '/user_list' ? logoUserDark : logoUser} alt="icon" />}>
            <Link to="/user_list">Users List</Link>
          </Menu.Item>
          <Menu.Item key="/custom_notification" icon={<img className='w-10' src={selectedKeys[0] == '/custom_notification' ? logoNotiDark : logoNoti} alt="icon" />}>
            <Link to="/custom_notification">Custom Notification</Link>
          </Menu.Item>
          {/* <Menu.SubMenu key="" title="Static Pages" icon={<img className='w-10' src={logoTerm} alt="icon" />} > */}
            <Menu.Item key="/faq" icon={<img className='w-10' src={selectedKeys[0] == '/faq' ? logoFaqDark : logoFaq} alt="icon" />}>
              <Link to="/faq">FAQ</Link>
            </Menu.Item>
            <Menu.Item key="/termsofservice" icon={<img className='w-10' src={selectedKeys[0] == '/termsofservice' ? logoTermDark : logoTerm} alt="icon" />}>
              <Link to="/termsofservice">Terms of Service</Link>
            </Menu.Item>
            <Menu.Item key="/howitworks" icon={<img className='w-10' src={selectedKeys[0] == '/howitworks' ? logoHowDark : logoHow} alt="icon" />}>
              <Link to="/howitworks">How it Works</Link>
            </Menu.Item>
            <Menu.Item key="/privacypolicy" icon={<img className='w-10' src={selectedKeys[0] == '/privacypolicy' ? logoPrivacyDark : logoPrivacy} alt="icon" />}>
              <Link to="/privacypolicy">Privacy Policy</Link>
            </Menu.Item>
          {/* </Menu.SubMenu> */}
          <Menu.Item key="/contactus" icon={<img className='w-10' src={selectedKeys[0] == '/contactus' ? logoContactDark : logoContact} alt="icon" />}>
            <Link to="/contactus">Contact Us</Link>
          </Menu.Item>
          <Menu.Item key="/introductory" icon={<img className='w-10' src={selectedKeys[0] == '/introductory' ? logoIntroDark : logoIntro} alt="icon" />}>
            <Link to="/introductory">Introductory</Link>
          </Menu.Item>
        </Menu>
      </Sider>

      <Drawer placement="right" onClose={() => setCollapsedDrawer(!collapsedDrawer)} open={collapsedDrawer} style={{ backgroundColor: 'white' }} >
        <Menu mode="inline" theme="light"
          selectedKeys={selectedKeys}
          onClick={handleMenuClick}
          style={{ paddingTop: "10px" }}>
          <Menu.Item key="/overview" icon={<img className='w-10' src={selectedKeys[0] == '/overview' ? logoOverviewDark : logoOverview} alt="icon" />}>
            <Link to="/overview">Overview</Link>
          </Menu.Item>
          <Menu.Item key="/user_list" icon={<img className='w-10' src={selectedKeys[0] == '/user_list' ? logoUserDark : logoUser} alt="icon" />}>
            <Link to="/user_list">Users List</Link>
          </Menu.Item>logoNotiDark
          <Menu.Item key="/custom_notification" icon={<img className='w-10' src={selectedKeys[0] == '/custom_notification' ? logoNotiDark : logoNoti} alt="icon" />}>
            <Link to="/custom_notification">Custom Notification</Link>
          </Menu.Item>
          <Menu.Item key="/faq" icon={<img className='w-10' src={selectedKeys[0] == '/faq' ? logoFaqDark : logoFaq} alt="icon" />}>
            <Link to="/faq">FAQ</Link>
          </Menu.Item>
          <Menu.Item key="/termsofservice" icon={<img className='w-10' src={selectedKeys[0] == '/termsofservice' ? logoTermDark : logoTerm} alt="icon" />}>
            <Link to="/termsofservice">Terms of Service</Link>
          </Menu.Item>
          <Menu.Item key="/howitworks" icon={<img className='w-10' src={selectedKeys[0] == '/howitworks' ? logoHowDark : logoHow} alt="icon" />}>
            <Link to="/howitworks">How it Works</Link>
          </Menu.Item>
          <Menu.Item key="/privacypolicy" icon={<img className='w-10' src={selectedKeys[0] == '/privacypolicy' ? logoPrivacyDark : logoPrivacy} alt="icon" />}>
            <Link to="/privacypolicy">Privacy Policy</Link>
          </Menu.Item>
          <Menu.Item key="/contactus" icon={<img className='w-10' src={selectedKeys[0] == '/contactus' ? logoContactDark : logoContact} alt="icon" />}>
            <Link to="/contactus">Contact Us</Link>
          </Menu.Item>
          <Menu.Item key="/introductory" icon={<img className='w-10' src={selectedKeys[0] == '/introductory' ? logoIntroDark : logoIntro} alt="icon" />}>
            <Link to="/introductory">Introductory</Link>
          </Menu.Item>
        </Menu>
      </Drawer>
    </>
  );
}

export default MenuApp